import React from 'react';
import { Link } from 'react-router-dom'; // Para la navegación
import './styles/style.css'; // Importa los estilos CSS

const Main = () => {
  return (
    <div>
      <div className="greetings">
        <div className="eres">
          <span>E</span>
          <span>r</span>
          <span>e</span>
          <span>s</span>
        </div>
        <div className="importante">
          <span>I</span>
          <span>m</span>
          <span>p</span>
          <span>o</span>
          <span>r</span>
          <span>t</span>
          <span>a</span>
          <span>n</span>
          <span>t</span>
          <span>e</span>
        </div>
      </div>
      <div className="description">
        <span>Eres una persona especial para mi 💖😊</span>
      </div>
      <div className="button">
        <button className="botones">
          <Link to="/flower" style={{ color: '#fff', textDecoration: 'none' }}>
            ¡Presiona aquí!
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Main;
