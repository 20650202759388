import React, { useState, useEffect, useRef } from 'react';

const Anim = () => {
  const audioRef = useRef(null); // Referencia para el audio
  const [lyrics, setLyrics] = useState(''); // Estado para las letras
  const [opacity, setOpacity] = useState(0); // Estado para la opacidad de las letras

  // Datos de las letras sincronizadas
  const lyricsData = [
    { text: "At the time", time: 15 },
  { text: "The whisper of birds", time: 18 },
  { text: "Lonely before the sun cried", time: 27 },
  { text: "Fell from the sky", time: 32 },
  { text: "Like water drops", time: 33 },
  { text: "Where I'm now? I don't know why", time: 41 },
  { text: "Nice butterflies in my hands", time: 47 },
  { text: "Too much light for twilight", time: 54 },
  { text: "In the mood for the flowers love", time: 59 },
  { text: "That vision", time: 67 },
  { text: "Really strong, blew my mind", time: 72 },    
  { text: "Silence Let me see what it was", time: 78 },
  { text: "I only want to live in clouds", time: 83 },
  { text: "Where I'm now? I don't know why", time: 91 },
  { text: "Nice butterflies in my hands", time: 97 },
  { text: "Too much light for twilight", time: 104 },
  { text: "In the mood for the flowers love", time: 108 },
  { text: "At the time", time: 144 },
  { text: "The whisper of birds", time: 148 },
  { text: "Lonely before the sun cried", time: 153 },
  { text: "Fell from the sky", time: 158 },
  { text: "Like water drops", time: 164 },
  { text: "Where I'm now? I don't know why", time: 169 },
  { text: "Nice butterflies in my hands", time: 176 },
  { text: "Too much light for twilight", time: 183 },
  { text: "In the mood for the flowers", time: 188 },
  { text: "Love.", time: 140 },
  ];

  // Actualizar letras con el tiempo del audio
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(audioRef.current.currentTime);
      const currentLine = lyricsData.find(
        (line) => currentTime >= line.time && currentTime < line.time + 6
      );
      if (currentLine) {
        const fadeInDuration = 0.1; // Duración de la aparición de las letras
        setOpacity(Math.min(1, (currentTime - currentLine.time) / fadeInDuration));
        setLyrics(currentLine.text);
      } else {
        setOpacity(0);
        setLyrics('');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <audio ref={audioRef} src="/assets/ChristianBasso&HaienQiu-Flowers.mp3" autoPlay />
      <div id="lyrics" style={{ opacity }}>
        {lyrics}
      </div>
    </div>
  );
};

export default Anim;
